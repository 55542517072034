module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{"default":"/opt/build/repo/src/layout/layout_blog.js","pro":"/opt/build/repo/src/layout/layout_pro.js","features":"/opt/build/repo/src/layout/layout_features.js","solutions_old":"/opt/build/repo/src/layout/layout_solutions.js"},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Trailguide AS","short_name":"Trailguide AS","start_url":"/","background_color":"#1c3d5a","theme_color":"#1c3d5a","display":"minimal-ui","icon":"src/images/logo-192x192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"74bd67bc275555e7b2acdaf3cdc0fdf4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-234601-12","head":false,"anonymize":true,"pageTransitionDelay":0,"siteSpeedSampleRate":10,"respectDNT":false,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
