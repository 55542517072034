import React from "react"
import { Link } from "gatsby"

import { GiHouse } from "react-icons/gi"
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa"
import { AiOutlineMail } from "react-icons/ai"
import { GoLaw } from "react-icons/go"
import { img } from "../components"

export function Footer(props) {
  const classes = `
    clear-both text-gray-300 bg-gray-700 text-xs sm:text-sm p-2 sm:p-8
    ${props.className || ""}
  `.trim()
  return (
    <footer className={classes}>
      <div className="flex flex-wrap justify-around">
        <Support {...props} />
        <Address {...props} />
        <Contact {...props} />
        <Legal {...props} />
      </div>
      <Copyright {...props} />
      <Pages {...props} />
      <SocialMediaLinks />
    </footer>
  )
}

function Support(props) {
  return (
    <Section>
      <Image>
        <img
          className="rounded-full w-8 h-8 sm:w-10 sm:h-10"
          alt="Peter Seidl"
          src={img("peter/peter-portrait.jpg")}
        />
      </Image>
      <div className="font-bold">
        {props.language === "no" ? "Brukerstøtte" : "Support"}
      </div>
      <div>Peter Seidl</div>
      <div>
        peter.seidl
        <span className="hidden">If you see this, apply for a job</span>@
        <span className="hidden">as a programmer at Trailguide</span>
        trailguide.no
      </div>
      <div>{props.language === "no" ? "Engelsk, Tysk" : "English, German"}</div>
    </Section>
  )
}

function Address(props) {
  return (
    <Section>
      <Icon>
        <GiHouse />
      </Icon>
      <div>
        Trailguide AS
        <br />
        Tuppeskogvegen 477
        <br />
        3550 Gol
        <br />
        {props.language !== "no" ? (
          <div>
            Norway
            <br />
            +47 916 36 159
          </div>
        ) : (
          <div>916 36 159</div>
        )}
      </div>
    </Section>
  )
}

function Contact(props) {
  return (
    <Section>
      <Icon>
        <AiOutlineMail />
      </Icon>
      <div>
        sales
        <span className="hidden">If you see this, apply for a job</span>@
        <span className="hidden">as a programmer at Trailguide</span>
        trailguide.no
        <br />
        {props.language === "no" ? (
          <Link className="text-gray-300 font-bold" to="/no/about">
            Om Trailguide AS
          </Link>
        ) : (
          <Link className="text-gray-300 font-bold" to="/about">
            About Trailguide AS
          </Link>
        )}
        <br />
        <a className="text-gray-300 font-bold" href="https://trailguide.net">
          trailguide.net (app)
        </a>
        <br />
      </div>
    </Section>
  )
}

function Legal(props) {
  return (
    <Section>
      <Icon>
        <GoLaw />
      </Icon>
      <div>
        <Link className="text-gray-300 font-bold" to="/legal/impressum">
          Impressum
        </Link>
        <br />
        <Link className="text-gray-300 font-bold" to="/legal/useragreement">
          {props.language === "no" ? "Brukeravtale" : "User agreement"}
        </Link>
        <br />
        <a className="text-gray-300 font-bold" href="/privacy.pdf">
          {props.language === "no" ? "Personvernerklæring" : "Privacy policy"}
        </a>
      </div>
    </Section>
  )
}

function Copyright(props) {
  return (
    <div className="text-center mt-12">
      &copy; Trailguide AS 2013 - {new Date().getFullYear()} |{" "}
      {props.language === "no"
        ? "Alle rettigheter forbeholdt"
        : "All rights reserved"}
    </div>
  )
}

function Pages(props) {
  return (
    <div className="flex justify-center text-xs my-6">
      <Link className="mx-1 text-gray-300 underline" to="/prices">
        Prices
      </Link>{" "}
      |
      <Link className="mx-1 text-gray-300 underline" to="/howto">
        How-To
      </Link>{" "}
      |
      <Link className="mx-1 text-gray-300 underline" to="/destination">
        Destinations
      </Link>
      |
      <Link className="mx-1 text-gray-300 underline" to="/coding">
        Coding blog
      </Link>
      |
      <Link
        className="mx-1 text-gray-300 underline"
        to="/news/trailguide_changelog"
      >
        Change log
      </Link>
      |
      <a
        className="mx-1 text-gray-300 underline"
        href="https://shop.trailguide.no"
      >
        Maps for sale
      </a>
    </div>
  )
}

function SocialMediaLinks() {
  return (
    <div className="flex justify-center text-2xl my-6">
      <a
        className="mx-2 text-gray-300"
        target="some"
        href="https://www.facebook.com/trailguide.net/"
      >
        <FaFacebook />
      </a>
      <a
        className="mx-2 text-gray-300"
        target="some"
        href="https://www.instagram.com/trailguideapp/"
      >
        <FaInstagram />
      </a>
      <a
        className="mx-2 text-gray-300"
        target="some"
        href="https://www.youtube.com/channel/UC9q29IipcshQPsFE1O2RHhg/videos"
      >
        <FaYoutube />
      </a>
    </div>
  )
}

function Section(props) {
  return <div className="mt-12">{props.children}</div>
}

function Image(props) {
  return <div className="h-10 sm:h-12">{props.children}</div>
}

function Icon(props) {
  return (
    <Image>
      <div className="text-2xl">{props.children}</div>
    </Image>
  )
}
