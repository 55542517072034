import React, { useState } from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"
import { FiMenu } from "react-icons/fi"

import { Home } from "./home"

const DROPDOWN_MENU = [
  { title: "Solutions", to: "/solutions", inNavBar: true },
  { title: "Products", to: "/products", inNavBar: true },
  { title: "Use cases", to: "/usecases", inNavBar: true },
  { title: "Blog", to: "/news", inNavBar: true },
  { title: "How-To", to: "/howto" },
  { title: "MTB Destinations", to: "/destination" },
  { title: "Coding office", to: "/coding" },
  { title: "Change Log", to: "/news/trailguide_changelog" },
  { title: "Maps for sale", href: "https://shop.trailguide.no" },
  { title: "Trailguide AS", to: "/about" },
]

const DROPDOWN_MENU_NO = [
  { title: "Løsninger", to: "/solutions", inNavBar: true },
  { title: "Produkter", to: "/no/products", inNavBar: true },
  { title: "Kunder", to: "/usecases", inNavBar: true },
  { title: "Blog", to: "/news", inNavBar: true },
  { title: "How-To", to: "/howto" },
  { title: "Reiseinspirasjon", to: "/destination" },
  { title: "Kodekontoret", to: "/coding" },
  { title: "Change Log", to: "/news/trailguide_changelog" },
  { title: "Kart til salgs", href: "https://shop.trailguide.no" },
  { title: "Trailguide AS", to: "/no/about" },
]

const PRODUCT_ITEMS = [
  { title: "Overview", path: "/products" },
  { title: "The Trailguide App", path: "/products/app" },
  { title: "White-label App", path: "/products/whitelabel" },
  { title: "Web Integration", path: "/products/plugin" },
  { title: "Trailguide Landing Page", path: "/products/landingpage" },
  { title: "Trailguide Presence", path: "/products/presence" },
  { title: "Custom Printed Maps", path: "/products/#wall_map" },
  { title: "Activity Manager", path: "/products/#activity_manager" },
  { title: "Collection Manager", path: "/products/#collection_manager" },
]

const PRODUCT_ITEMS_NO = [
  { title: "Oversikt", path: "/no/products" },
  { title: "Trailguide-appen", path: "/products/app" },
  { title: "White-label App", path: "/products/whitelabel" },
  { title: "Web-integrasjon", path: "/products/plugin" },
  { title: "Trailguide landingsside", path: "/products/landingpage" },
  { title: "Synlighet i Trailguide", path: "/products/presence" },
  { title: "Skreddersydde veggkart", path: "/no/products/#wall_map" },
  { title: "Activity Manager", path: "/no/products/#activity_manager" },
  { title: "Collection Manager", path: "/no/products/#collection_manager" },
]

const SOLUTION_ITEMS = [
  { title: "Destinations", path: "/solutions/destination" },
  { title: "Resorts", path: "/solutions/resort" },
  { title: "Bike shops", path: "/solutions/bikeshop" },
]

export function Header(props) {
  const { mode = "dark" } = props

  return (
    <header
      className={`h-16 flex absolute top-0 left-0 right-0 z-40 ${color(mode)}`}
    >
      <Home
        language={props.language}
        className={`flex absolute top-0 left-0 ${color(mode)}`}
      />
      <nav className="w-full h-16 px-4 pt-4 text-base flex flex-nowrap justify-end items-start">
        {currentLanguage(props)}
        <div className="hidden md:block">
          {props.language === "no" ? (
            <NavBarNo mode={mode} />
          ) : (
            <NavBar mode={mode} />
          )}
        </div>
        <div className="hidden md:block">
          <DropdownMenu
            name="desktop"
            navBar={true}
            items={props.language === "no" ? DROPDOWN_MENU_NO : DROPDOWN_MENU}
          />
        </div>
        <div className="block md:hidden">
          <DropdownMenu
            name="phone"
            navBar={false}
            items={props.language === "no" ? DROPDOWN_MENU_NO : DROPDOWN_MENU}
          />
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

function currentLanguage(props = {}) {
  const { mode = "dark" } = props
  return (
    <div className="mr-1 md:mr-2">
      <Link
        className={`${color(mode)} ${
          props.language === "no" ? "font-bold" : ""
        }`}
        to="/no"
      >
        no
      </Link>
      <span className={`mx-px opacity-25 ${color(mode)}`}> | </span>
      <Link
        className={`${color(mode)}  ${
          props.language !== "no" ? "font-bold" : ""
        }`}
        to="/"
      >
        en
      </Link>
    </div>
  )
}

function NavBar(props) {
  const { mode = "dark" } = props
  const [productMenu, showProductMenu] = useState(false)
  const [solutionMenu, showSolutionMenu] = useState(false)

  return (
    <>
      <div className="inline relative">
        <HeaderLink
          color={color(props.mode)}
          active={/solutions/.test(props.path)}
          to="/solutions/"
          onMouseEnter={() => showSolutionMenu(true)}
          onMouseLeave={() => showSolutionMenu(false)}
        >
          Solutions
        </HeaderLink>
        {solutionMenu && (
          <DropdownProductSolutions
            productItems={SOLUTION_ITEMS}
            onMouseEnter={() => showSolutionMenu(true)}
            onMouseLeave={() => showSolutionMenu(false)}
          />
        )}
      </div>
      <div className="inline relative">
        <HeaderLink
          color={color(props.mode)}
          active={/products/.test(props.path)}
          to="/products/"
          onMouseEnter={() => showProductMenu(true)}
          onMouseLeave={() => showProductMenu(false)}
        >
          Products
        </HeaderLink>
        {productMenu && (
          <DropdownProductSolutions
            productItems={PRODUCT_ITEMS}
            onMouseEnter={() => showProductMenu(true)}
            onMouseLeave={() => showProductMenu(false)}
          />
        )}
      </div>
      <HeaderLink
        color={color(mode)}
        active={/usecases/.test(props.path)}
        to="/usecases/"
      >
        Use cases
      </HeaderLink>
      <HeaderLink
        color={color(mode)}
        active={/news/.test(props.path)}
        to="/news"
      >
        Blog
      </HeaderLink>
    </>
  )
}

function NavBarNo(props) {
  const { mode = "dark" } = props
  const [productMenu, showProductMenu] = useState(false)
  const [solutionMenu, showSolutionMenu] = useState(false)

  return (
    <>
      <div className="inline relative">
        <HeaderLink
          color={color(props.mode)}
          active={/solutions/.test(props.path)}
          to="/solutions/"
          onMouseEnter={() => showSolutionMenu(true)}
          onMouseLeave={() => showSolutionMenu(false)}
        >
          Løsninger
        </HeaderLink>
        {solutionMenu && (
          <DropdownProductSolutions
            productItems={SOLUTION_ITEMS}
            onMouseEnter={() => showSolutionMenu(true)}
            onMouseLeave={() => showSolutionMenu(false)}
          />
        )}
      </div>
      <div className="inline relative">
        <HeaderLink
          color={color(mode)}
          active={/no\/products/.test(props.path)}
          to="/no/products/"
          onMouseEnter={() => showProductMenu(true)}
          onMouseLeave={() => showProductMenu(false)}
        >
          Produkter
        </HeaderLink>
        {productMenu && (
          <DropdownProductSolutions
            productItems={PRODUCT_ITEMS_NO}
            onMouseEnter={() => showProductMenu(true)}
            onMouseLeave={() => showProductMenu(false)}
          />
        )}
      </div>
      <HeaderLink
        color={color(mode)}
        active={/usecases/.test(props.path)}
        to="/usecases/"
      >
        Kunder
      </HeaderLink>
      <HeaderLink
        color={color(mode)}
        active={/news/.test(props.path)}
        to="/news"
      >
        Blog
      </HeaderLink>
    </>
  )
}

function HeaderLink(props) {
  const { color = "text-black" } = props
  const classes = `
    px-1 lg:px-2 pb-2 whitespace-no-wrap ${color}
    font-semibold border-b-4 hover:border-orange-400
    ${props.active ? "border-orange-300" : "border-transparent"}
  `
  if (props.to) {
    return (
      <Link
        className={classes.trim()}
        to={props.to}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
      >
        {props.children}
      </Link>
    )
  } else if (props.href) {
    return (
      <a className={classes.trim()} href={props.href}>
        {props.children}
      </a>
    )
  } else {
    return ""
  }
}

function DropdownProductSolutions(props) {
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <ul
      className="absolute md:left-0 -ml-2 bg-white p-4 border shadow-lg rounded"
      onMouseLeave={props.onMouseLeave}
      onMouseEnter={props.onMouseEnter}
    >
      {props.productItems.map((item, index) => (
        <li key={index}>
          <Link
            className="block text-gray-800 py-2 border-b-4 border-transparent hover:border-orange-400 whitespace-no-wrap"
            to={item.path}
          >
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  )
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
}

function DropdownMenu(props) {
  const [visible, setVisible] = useState(false)

  let menu =
    "absolute right-0 top-0 m-4 mt-16 bg-gray-800 px-6 py-2 rounded shadow-md"
  if (visible) {
    menu += " block"
  } else {
    menu += " hidden"
  }

  let items = props.items
  if (props.navBar) {
    items = items.filter((item) => !item.inNavBar)
  }

  return (
    <>
      <FiMenu
        className="ml-3 text-2xl cursor-pointer"
        onClick={() => setVisible(!visible)}
      />
      <div className={menu}>
        {items.map((item) => (
          <DropdownItem key={item.to || item.href} {...item} />
        ))}
      </div>
    </>
  )
}

function DropdownItem(props) {
  if (props.to) {
    return (
      <Link
        className="block text-gray-200 my-3 text-base whitespace-no-wrap hover:underline"
        to={props.to}
      >
        {props.title}
      </Link>
    )
  } else if (props.href) {
    return (
      <a className="text-white my-3 block hover:underline" href={props.href}>
        {props.title}
      </a>
    )
  } else {
    return null
  }
}

function color(mode) {
  if (mode === "light") {
    return "text-gray-100"
  } else {
    return "text-black"
  }
}
