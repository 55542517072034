import React from "react"

export function MainSectionHeading(props) {
  const { className = "" } = props
  const classes = `
    px-2 sm:px-16
    text-3xl sm:text-4xl font-medium text-gray-900
    text-center leading-tight mx-auto
    ${className}
  `.trim()
  return <h2 className={classes}>{props.children}</h2>
}

export function SubSectionHeading(props) {
  const { className = "" } = props
  const classes = ` text-2xl sm:text-3xl ${className} `.trim()
  return <h3 className={classes}>{props.children}</h3>
}
